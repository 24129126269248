import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function Image(props) {
    const { src, radius, padding, style } = props
    return (
        <div
            style={{
                display: "flex",
                placeContent: "center",
                placeItems: "center",
                width: "100%",
                height: "100%",
                padding: padding,
            }}
        >
            <img
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    borderRadius: radius,
                }}
                src={src}
            />
        </div>
    )
}

Image.defaultProps = {
    radius: 10,
    padding: 10,
}

addPropertyControls(Image, {
    src: {
        title: "Image",
        type: ControlType.Image,
    },
    radius: {
        title: "Radius",
        type: ControlType.Number,
        min: 0,
        max: 100,
        step: 5,
        displayStepper: true,
    },
    padding: {
        title: "Padding",
        type: ControlType.Number,
        min: 0,
        max: 100,
        step: 5,
        displayStepper: true,
    },
})
